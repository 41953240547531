import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { FacebookLogInDto } from '../dtos';
import { FBLoginResponse, FbUserData } from '../models';
import { FbApi } from '../consts';
import { environment } from '@xspot-app/common';
import { isPlatformServer } from '@angular/common';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: {
      init: (params: {
        appId: string;
        autoLogAppEvents: boolean;
        xfbml: boolean;
        version: string;
      }) => void;
      login: (
        callback: (response: Partial<FBLoginResponse>) => void,
        options: { scope: string; return_scopes: boolean }
      ) => void;
      api: (
        path: string,
        params: { fields: string },
        callback: (res: FbUserData) => void
      ) => void;
      logout: (callback: () => void) => void;
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class FacebookAuthService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  public initializeFacebookSDK(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    window.fbAsyncInit = () => {
      if (window.FB) {
        window.FB.init({
          appId: environment.metaAppId,
          autoLogAppEvents: isDevMode(),
          xfbml: true,
          version: 'v18.0',
        });
      }
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';

      if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, 'script', 'facebook-jssdk');
  }

  public loginWithFacebook(): Observable<FacebookLogInDto> {
    window.FB.logout(() => {});
    return this.facebookLogIn(...FbApi.scopes);
  }

  private facebookLogIn<T>(...args: string[]): Observable<T> {
    return new Observable<T>(observer => {
      window.FB.login(
        (response: Partial<FBLoginResponse>) => {
          if (response.authResponse) {
            const { accessToken } = response.authResponse;
            window.FB.api(
              '/me',
              { fields: FbApi.fields.join(',') },
              (res: FbUserData) => {
                observer.next({ accessToken, ...res } as T);
                observer.complete();
              }
            );
          } else {
            observer.error('Facebook login was not successful.');
          }
        },
        { scope: args.join(','), return_scopes: true }
      );
    });
  }
}
